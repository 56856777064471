// import clipboard from '@/utils/clipboard'

/**
 * 全局混入型
 */
module.exports = {
  beforeCreate: function beforeCreate() {},
  data: function data() {
    return {
      /**
       * 页面加载是否ready
       */
      isReady: false,
      ossUrl: process.env.VUE_APP_OSS_URL,
      ossCdnUrl: process.env.VUE_APP_OSS_URL
    };
  },
  computed: {
    isDetectWebp: function isDetectWebp() {
      return this.$tool.isDetectWebp();
    },
    defaultImgUrl: function defaultImgUrl() {
      return this.ossCdnUrl + 'static/default.png';
    },
    loadingImgUrl: function loadingImgUrl() {
      return this.ossCdnUrl + 'static/default.png';
    },
    errorImgUrl: function errorImgUrl() {
      return this.ossCdnUrl + 'static/default.png';
    }
  },
  methods: {
    /**
     * 将图片转化为带cdn域名的全路径，如已经带有http(s)则直接返回
     * @param path 文件or图片路径
     * @param ossStyle 若设备不支持webp图片格式，将图片转化为对应的后缀
     * @returns {*}
     */
    gToCdnUrl: function gToCdnUrl(path) {
      var ossStyle = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'convert2jpeg';
      return this.__toUrl(path, ossStyle, true);
    },

    /**
     * 将图片转化为域名的全路径，如已经带有http(s)则直接返回（不带cdn）
     * @param path 文件or图片路径
     * @param ossStyle 若设备不支持webp图片格式，将图片转化为对应的后缀
     * @returns {*}
     */
    gToUrl: function gToUrl(path) {
      var ossStyle = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'convert2jpeg';
      return this.__toUrl(path, ossStyle, false);
    },

    /**
     *  私有方法
     * @param path
     * @param ossStyle
     * @param isCdn 是否转化为cdn域名
     * @returns {*}
     * @private
     */
    __toUrl: function __toUrl(path) {
      var ossStyle = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'convert2jpeg';
      var isCdn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      if (!path) {
        return path;
      }

      return (isCdn ? this.ossCdnUrl : this.ossUrl) + path;
    } // gCopy(text, event) {
    //     clipboard(text, event)
    // }

  }
};