import { Loading } from 'element-ui';
import _ from 'lodash';
var loading = null;
var needRequestCount = 0; // 开启loading状态

var startLoading = function startLoading() {
  var headers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  loading = Loading.service({
    lock: true,
    text: headers.text || '加载中……',
    background: 'rgba(0, 0, 0, 0.6)',
    target: headers.target || 'body'
  });
}; // 关闭loading状态


var endLoading = _.debounce(function () {
  loading.close();
  loading = null;
}, 0);

export var showScreenLoading = function showScreenLoading(headers) {
  if (needRequestCount === 0 && !loading) {
    startLoading(headers);
  }

  needRequestCount++;
};
export var hideScreenLoading = function hideScreenLoading() {
  if (needRequestCount <= 0) return;
  needRequestCount--;
  needRequestCount = Math.max(needRequestCount, 0);

  if (needRequestCount === 0) {
    endLoading();
  }
};
export default {};