//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Logo from '@/assets/images/logo.png';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '寻觅运营管理后台',
      logo: Logo
    };
  }
};