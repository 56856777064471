var tool = {};
/**
 * 模拟alert
 * @param _this 传入this即可
 * @param content {string} 提示内容
 * @param title {string} 提示标题，可选
 * @param callback 点击确认后回调函数，可选
 */

tool.alert = function (_this, content) {
  var title = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '提示';

  var _callback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

  _this.$alert(content, title, {
    confirmButtonText: '确定',
    callback: function callback(action) {
      if (_callback) {
        _callback();
      }
    }
  });
};

tool.scrollTo = function () {
  var x = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var y = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'smooth';
  window.scrollTo({
    top: x,
    left: y,
    behavior: type // 滚动行为：smooth平滑滚动，instant瞬间滚动，默认值auto，等同于instant

  });
};

export { tool };